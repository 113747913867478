/** @jsx jsx */
import { merge} from "theme-ui"
import { tailwind } from "@theme-ui/presets"
//import baseTheme from 'gatsby-theme-blog/src/gatsby-plugin-theme-ui'
//import "typeface-roboto"

export default merge(tailwind, {
  useColorSchemeMediaQuery:false,
 
  useCustomProperties: false,

  colors: {
    primary: tailwind.colors.black[6],
    secondary: tailwind.colors.indigo[6],
    background: tailwind.colors.white,
    modes: {

      light: {
        text: tailwind.colors.black,
        primary: tailwind.colors.white,
        secondary: tailwind.colors.green,
        background: tailwind.colors.white,
        textMuted: tailwind.colors.gray[5],
      },
      dark: {
        text: tailwind.colors.white,
        primary: tailwind.colors.white,
        background: tailwind.colors.black,
        textMuted: tailwind.colors.gray[5],
      },
    },
  },
  breakpoints:[`40rem`,`56rem`,`64rem`],
  fonts: {  
    body: 'Roboto,system-ui, sans-serif',
    heading: 'Avenir Next, sans-serif',
    monospace: 'Menlo, nonospace'
  },
  
text: {
  default:{
   color:'text',
  
  fontSize:1,
  marginBottom:'1.05rem',
  display:'block'
  },
  absatz: {
    fontSize:[1,1,2],
    fontWeight:400,
    lineHeight: 1.5,
  color:`text`,
  mb:3,
  display:'block'
  },
  photo:{
    color:'text',
    fontWeight: 400,
    marginTop:'3px',
    display: 'block',
    fontSize: ".8rem",
    lineHeight: 1.3,
    
  },
  zitat:{
    color:'text',
   fontWeight: 400,
   marginTop:'20px',
   display: 'block',
   fontSize: 4,
   lineHeight: 1.3,
   p:2
  },
  fettmt: {
   color:'text',
   fontWeight: 500,
   marginTop:'20px',
   display: 'block'
  },
  spezi:{
    display:"block",
    paddingBottom:"20px",
    fontSize:[1,2],
    lineHeight:1.2,
    color:'text'
  },

  normal:{
    fontSize:[1,1,1],
    color:`text`,
    marginY:4,
  },
  head: {
    fontSize:2,
  letterSpacing:'1rem',
  color:`text`,
  },
  heading: {
    fontSize:[2,2,3],
    fontWeight:400,
  color:`text`,
  mb:3
  },
  titel: {
  fontSize:[2,3],
  letterSpacing:'.01rem',
  color:`text`,
  fontWeight: 500,
  py:'10px',
  mt:'20px'
  },
  
 
},
links: {
 default: {
   color: 'text'
 }
},
heading: {
  normal:{
  color:`text`,
  },
  head: {
  fontSize:[1,2,3],
  letterSpacing:'1rem',
  color:`text`,
  fontWeight:100,
  },
  
 
},
 
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      border:`1x solid blue`,
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      
      borderColor: 'muted',
   
      width: `100%`,
      border:`1x solid blue`,
      img: {
        width: `100%`,
        maxWidth:600,
        objectFit: `cover`
      },
      h1: {
        fontSize:[1,2,3]
      }
    },
   
  },
  
  layout: {
    footer: {
      textAlign: `center`,
      display: `block`,
      color: `textMuted`,
      px: [2, 3],
      py: [3, 4], 
      fontSize:[4,6,8],
    },
    
    header: {
      background:`background`,
      py: [2, 3],
      fontSize: 2,
      display: `flex`,
      alignItems: `center`,
      flexWrap: `wrap`,
      
    },
    main: {
      position: `relative`,
      minHeight: `70vh`,
      px:[3,0],
      background:`background`,
      
    },
  
   
    
  },
  
  
  styles: {
    root: {
      maxWidth:'873px',
      display:'flex',
      flexDirection:`column`,
     
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
    },
    span: {
      color: `text`,
    },
    a:{
       color:`text`,
       textDecoration:`none`,
       secondary: {
       
        fontWeight: 300,
        textDecoration: `none`,
        
        color:`text`,
        ml:`3`,
        letterSpacing:[`0rem`,`0.16rem`],
       
      },
      
    },
    
    p: {
      fontFamily: `Roboto`,
      fontSize: 1,
      color:`text`,
     
 
      
    },
    picture:{
      opacity: 1,
    },
    h1: {
 
       color: `text`,
      fontSize: [3,3,4],
      fontWeight: [500,500],
      fontFamily:'Roboto'
      },
   
    h2: {
      mt: 1,
      mb:2,
      fontWeight:[500,500],
      color:`text`,
      fontSize: [2, 2,3],
     
    },
    h3: {
      fontSize: [2, 2,4],
      pt: 2,
      color:`text`,
      fontWeight:300,
      textTransform:`uppercase`,
    
    },
    
    h4: {
      fontSize: 2,
      fontWeight:400,
      mt: 2,
      color:`text`,
      
    }, 
  
    h5: {
      fontSize: [1, 2],
      fontWeight:400,
      color:`text`,
    },
    
    h6: {
      fontSize: 1,
      mb: 2,
      fontWeight:200,
      px:[2,2,2,0],
      color:`text`,
    },
  
  
  ul: {
    listStyle:`none`,
    mt:3,
  },
  li: {
    fontFamily: `Roboto`,
    fontSize: 1,
    pb:1,
    color:`text`,
    letterSpacing: `-0.003em`,
  },
  

    
   
}
})
