export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
  
  

  // const answer = window.confirm(
  //   `Neue Inhalte!  ` +
  //     `Um die neuen Inhalte zu sehen, kann ein Refresh - ein Neuladen des Browsers - notwendig sein.`
  // )
  // if (answer === true) {
  //   window.location.reload()
  // }
  window.location.reload()
}